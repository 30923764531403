// 资产库位
<template>
  <div class="contain">
    <van-field v-model="fieldValue" is-link readonly placeholder="请选择" @click="show = true" />
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="value"
        title="请选择"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import { getLocationInfo } from '@/api/OutboundMeasurementTable/index';
export default {
  props: {
    campus_name: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      show: false,
      options: [],
      value: '',
      fieldValue: '',
    };
  },
  watch: {
    campus_name: {
      handler(v) {
        this.getData(v);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async getData(v) {
      try {
        const { data, status } = await getLocationInfo({ campus_name: v });
        if (status === 0) {
          this.options = this.formateData(data);
        } else {
          this.$message.error('获取资产库存接口出错!');
        }
      } catch (e) {
        this.$message.error('获取资产库存接口出错!');
      }
    },
    // 对级联选择器数据格式转化
    formateData(data) {
      return data.map((item) => {
        const newItem = {
          value: item.text,
          text: item.text,
        };
        if (item.children) {
          newItem.children = this.formateData(item.children);
        }
        return newItem;
      });
    },
    onFinish({ selectedOptions }) {
      try {
        if (!selectedOptions.length) return;
        const selectData = selectedOptions.map((v) => v.text);
        this.fieldValue = selectedOptions.map((option) => option.text).join('/');
        this.$emit('selection-change', selectData);
        this.show = false;
      } catch (error) {
        console.log(error, 'error');
      }
    },
  },
};
</script>

<style></style>
